<template>
  <sdPageHeader title="Customers">
  </sdPageHeader>
  <Main>
    <sdCards headless>
      <a-row :gutter="15">
        <a-col :xs="24">
          <TopToolBox>
            <a-row :gutter="15" class="justify-content-between">
              <a-col :lg="6" :xs="24">
                <div class="table-search-box">
                  <SearchInputStyled
                    style="width: 100%;"
                    class="certain-category-search"
                    placeholder="Search Customers"
                    @search="onSearching"
                  >
                    <a-input>
                      <template #suffix>
                        <sdFeatherIcons type="search"/>
                      </template>
                    </a-input>
                  </SearchInputStyled>
                </div>
              </a-col>
            </a-row>
          </TopToolBox>
        </a-col>
      </a-row>
      <a-row :gutter="15">
        <a-col :md="24">
          <TableWrapper class="table-order table-responsive">
            <a-table
              rowKey="id"
              :columns="fields"
              :dataSource="customers"
              @change="handleTableChange"
              :pagination="{ pageSize: perPage, showSizeChanger: false, total: totalData}"
            >
            <template #is_verified="{text: is_verified}">
              <span class="status Success" v-if="is_verified">Verified</span>
            </template>
            <template #action="{record}">
              <div class="table-actions">
                <sdButton @click="navigateTo(`customers/setup/${record.encrypt_id}`)" class="btn-icon" type="info" shape="circle">
                  <sdFeatherIcons type="edit" size="16" />
                </sdButton>
              </div>
            </template>
            </a-table>
          </TableWrapper>
        </a-col>
      </a-row>
    </sdCards>
    <a-modal
      v-model:visible="deleteConfirmVisible"
      title="Plan remove confirmation"
      :confirm-loading="isDeleting"
      :centered="true"
      ok-text="Remove"
      cancel-text="Cancel"
      @ok="handleDeleteModal"
      :ok-button-props="okButtonProps"
    >
      <p>Are you sure you want to delete this Plan ?</p>
    </a-modal>
  </Main>
</template>
<script>
import { TopToolBox, SearchInputStyled } from './Package';
import Notification from "@/services/Notification";

import { API } from "@/services/api";
import { Main, TableWrapper } from '../../styled';

import { theme } from '@/config/theme/themeVariables';

export default {
  name: "Packages",
  components: { TopToolBox, Main, TableWrapper, SearchInputStyled },
  computed: {
    initial: function() {
      return this.$store.getters.initial;
    },
    
  },
  data() {
    return {
      search: "",
      status: "",
      perPage: 10,
      totalData: 0,
      currentPage: 1,
      sortField: '',
      sortOrder: '',
      isSearch: false,
      fetching: false,
      isDeleting: false,
      deleteConfirmVisible: false,
      deleteId: '',
      customers: [],
      filterKey: [{label: 'Winner', value: 1}, {label: 'Lost', value: 0}],
      fields: [
        { title: 'Id', dataIndex: 'id', key: 'id',sorter : true,sort_index:0},
        { title: 'Name', dataIndex: 'name', key: 'name',sorter : true,sort_index:1},
        { title: 'Email', dataIndex: 'email',sorter : true,sort_index:2},
        { title: 'Verified', dataIndex: 'is_verified',
          slots: { customRender: 'is_verified' }
        },
        { title: 'Action', dataIndex: 'action', key: 'action',
          slots: { customRender: 'action' },
        },
      ],
      okButtonProps: {
        style: {
          backgroundColor: theme['primary-color'],
          borderWidth: 0,
        },
      },
    };
  },
  methods: {
    navigateTo: function (path = "") {
      if (path) {
        this.$router.replace({path});
      }
    },
    async getCustomers() {
      try {
        this.fetching = true;
        const params = {
          page: this.currentPage,
          limit: this.perPage,
          search: this.search,
          status: this.status,
          sort_field: this.sortField,
          sort_order: this.sortOrder,
        };
        const datRes = await API.getCustomers({ params });
        const { data: sData, status, message } = datRes.data;
        if (!status && message) {
          Notification.show(message, {type: "error",title: "Error"});
        } else {
          this.customers = sData.users;
          this.totalData = sData.total_rows;
        }
      } catch (error) {
        if (error?.message) {
          Notification.show(error.message, {type: "error",title: "Error"});
        }
      } finally {
        this.fetching = false;
      }
    },
    onSearching(text) {
      this.search = text;
      if (this.searchTimeout) clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this.getCustomers();
      }, 500);
    },
    handleTableChange(page,filters, sorter) {
      if (this.currentPage != page.current) {
        this.currentPage = page.current;
        setTimeout(() => {
          this.getCustomers();
        }, 300);
      }
      if(typeof sorter.column.sort_index != "undefined" && sorter.order != "" ) {
        this.sortField = sorter.column.sort_index;
        this.sortOrder = "asc";
        if(sorter.order != "" && sorter.order == "descend") {
          this.sortOrder = "desc";
        }
        this.getCustomers();
      }
    },
    async handleDeleteModal() {
      try {
        const delRes = await API.deletePackages({id: this.deleteId});
        const {status, message} = delRes.data;
        if (message) {
          Notification.show(message, {
            type: status ? "success" : "error",
            title: status ? "Success" : "Error",
          });
        }
        if (status) {
          this.getCustomers();
        }
      } catch (error) {
        if (error?.message) {
          Notification.show(error.message, { type: "error", title: "Error" });
        }
      } finally {
        this.deleteConfirmVisible = false;
      }
    },
    onRemovePress(id) {
      this.deleteId = id;
      this.deleteConfirmVisible = true;
    },
  },
  created() {
    this.getCustomers();
  }
}

</script>
